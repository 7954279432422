import React, { useState, useEffect } from 'react';
import { Grid, useTheme, Box, Paper, Skeleton, Typography, Switch, Button, Divider, Input, Slider } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { Link } from 'react-router-dom';

import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'

import {
  useAppContext,
} from 'clnt-common';

import {
  DonateIntro,
} from '#src';

let isBrowser = false;
if (typeof window !== 'undefined') {
  isBrowser = true;
}

/* FLOW


   1) Load /donate
   .  > fetch publishableKey
   .  > create stripe instance using publishableKey
   .  > $ amount capture > 
   .  > click continue   >
   .  <Payment>   OR   <Completion>


   2) <Payment amount stripeInstance >
   .  > 'create-payment-intent' with specified amount and get back client secret
   .  > <Elements> WITH <CheckoutForm> as child
          
   3) <Elements stripe instance & clientSecret>
   .  > 

   4) <CheckoutForm>            
   .  > <form>
   .  > onSubmit
   .  > <LinkAuthenticationElement>    TODO can we capture/prefill Name & zip code
   .  > <PaymentElement>
   .  > Button: Pay Now
   .  > Upon Submission: REST API stripe.confirmPayment()
   .  > Pass on payment completion response page: /donate

   5) <Completion>
   .  > If URL has 'payment_intent_client_secret'
   .  > Retrieve stripe.retrievePaymentIntent object from STRIPE
   .  > Display error msg   OR   paymentIntent.id

   

*/

// Used from within <Payment/>
function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const { appState, dispatch } = useAppContext();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !isBrowser) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    
    const winLocOrigin = isBrowser ? window.location.origin : 'https://localhost';
    
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: `${window.location.origin}/completion`,
        return_url: `${winLocOrigin}/donate`,
      },
    });
    
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }
  
  const prefillEmail = appState?.user?.email ?
    { defaultValues: {email: appState.user.email} }
    :
    { defaultValues: {email: ''} };
  
  return (
    <form style={{
      border: '#F6F9FC solid 1px',
      borderRadius: '3px',
      padding: '20px',
      margin: '40px',
      boxShadow: '0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
    }}
      onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
        // Access the email value like so:
        // onChange={(event) => {
            //  setEmail(event.value.email);
            // }}
        //
        // Prefill the email field like so:
        options={{ defaultValues: { ...prefillEmail.defaultValues } }}
      />
      <PaymentElement id="payment-element" />
    
      <button style={{
	backgroundColor: '#635BFF',
	borderRadius: '3px',
	color: 'white',
	border: 0,
	padding: '12px 16px',
	marginTop: '16px',
	fontWeight: 600,
	cursor: 'pointer',
	transition: 'all 0.2s ease',
	display: 'block',

	//&&:hover filter: 'contrast(115%)'
	
	//button:active {
	//transform: translateY(0px) scale(0.98);
	//filter: brightness(0.9);
	//}
	//button:disabled {
	//opacity: 0.5;
	//cursor: none;
	//}
     }} disabled={isLoading || !stripe || !elements} id="submit" >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

function Payment(props) {
  const { stripePromise, amount } = props;
  const [ clientSecret, setClientSecret ] = useState('');

  const { appState, dispatch } = useAppContext();
  const host = appState.getEnvVar.API_SRVR_PYMT_URL;   // "https://apipymt-kripa-com.docker.localhost"
  
  useEffect(() => {
    // Need an amount to create a payment intent
    const appCode = 'RECL';   // This is used in statement desc   IE: 'KRIPA RECL 99'
    const planId = '99';      // This is used in statement desc
    async function fetchData() {
      const intent = await fetch(`${host}/create-payment-intent?amount=${amount}&appCode=${appCode}&planId=${planId}`, {
	headers: {
          'Content-Type': 'application/json',
	  'Authorization': `Bearer ${appState.token}`
	},
	}).then(async (r) => r.json());
      setClientSecret(intent.clientSecret);
    }
    
    fetchData();
  }, []);
  
  const paymentHtml = clientSecret && stripePromise ?
    (
      <>
      <h1>Payment</h1>
      <Elements stripe={stripePromise} options={{ clientSecret, }}>
	<CheckoutForm />
      </Elements>
      </>
    )
    :
    null;
  
  return paymentHtml;
}
	  
// The completion URL:
// http://localhost:8009/donate?payment_intent=pi_3Q....&payment_intent_client_secret=pi_3Q....&redirect_status=succeeded
function Completion(props) {
  const [ messageBody, setMessageBody ] = useState('');
  const { stripePromise } = props;

  useEffect(() => {
    if (!stripePromise || !isBrowser) return;

    const winLoc = isBrowser ? window.location : 'https://localhost';
    stripePromise.then(async (stripe) => {
      const url = new URL(winLoc);
      const clientSecret = url.searchParams.get('payment_intent_client_secret');
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      setMessageBody(error ? `> ${error.message}` : (
        <>&gt; Payment {paymentIntent.status}: <a style={{color: 'var(--accent-color)', fontWeight: '900' }} href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
      ));
    });

  }, [stripePromise]);
  const dispMsg = messageBody ? 'block' : 'none';
  return (
    <>
    <h1>Thank you!</h1>
    <h3>Payment Successfully Processed</h3>
    <h5>Please check your email for confirmation.</h5>
    </>
  );
}

/*
    <div style={{
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, \'Courier New\'',
      display: dispMsg, // hide initially, then show once the first message arrives
      backgroundColor: '#0A253C',
      color: '#00D924',
      padding: '20px',
      margin: '20px 0',
      borderRadius: '3px',
      fontSize: '2rem'
    }}
      role="alert">{messageBody}</div>
    </>
*/
	  
/* References:
   DonateDtls is replicated from:
     https://docs.stripe.com/samples : Accept a payment with a payment element
     https://github.com/stripe-samples/accept-a-payment/tree/main/payment-element/client/react-cra
   The server side code is in pymtsvc.class.js:handlePymt()
     https://github.com/stripe-samples/accept-a-payment/blob/main/payment-element/server/node/server.js


   This uses the concept of Single Payments (vs Subscription Payments)
*/

export { DonateDtls };
const DonateDtls = props => {
  const theme = useTheme();

  let isDonateComplete = false;

  const settingsOnetime   = { presetAmount: 100, maxAmount: 1000, inputStep: 10, inputMin: 0, inputMax: 1000 };
  //const settingsRecurring = { presetAmount:  50, maxAmount: 1000, inputStep: 10, inputMin: 0, inputMax: 1000 };

  const { appState, dispatch } = useAppContext();

  //const [isRecurring, setIsRecurring] = React.useState(false);
  const [ settings, setSettings ] = useState(settingsOnetime);

  const [sliderAmount, setSliderAmount] = React.useState(settings.presetAmount);

  const [ stripePromise, setStripePromise ] = useState(null);
  const [ isContinue, setIsContinue ] = useState('');

  const host = appState.getEnvVar.API_SRVR_PYMT_URL;   // "https://apipymt-kripa-com.docker.localhost"
  
  useEffect(() => {

    async function fetchData() {
      const { publishableKey } = await fetch(`${host}/configpymt`, {
	headers: {
          'Content-Type': 'application/json',
	  'Authorization': `Bearer ${appState.token}`
	},
      }).then(async (r) => await r.json());
      console.log('DonateDtls:useEffect publishableKey : ' + publishableKey);
      setStripePromise(loadStripe(publishableKey));
    };

    fetchData();
  }, []);

  if (isBrowser) {
    const winLoc = isBrowser ? window.location : 'https://localhost';

    const url = new URL(winLoc);
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    isDonateComplete = !!clientSecret;
  }

  //
  // Handlers
  //
  /*
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings(event.target.checked ? settingsRecurring : settingsOnetime);
    setIsRecurring(event.target.checked);
    setSliderAmount(event.target.checked ? settingsRecurring.presetAmount : settingsOnetime.presetAmount);
  };
  */

  const handleSliderChange = (event, newValue) => {
    setSliderAmount(Number(newValue));
  };
  const handleInputChange = (event) => {
    setSliderAmount(event.target.value === '' ? 0 : Number(event.target.value));
  };
  const handleInputBlur = () => {
    if (sliderAmount < 0) {
      setSliderAmount(0);
    }
    else {
      if (sliderAmount > settings.maxAmount) {
	setSliderAmount(settings.maxAmount);
      }
    }
  };
  const handleContinue = async () => {
    setIsContinue(true);
  };

  const continueHtml =
    <Button
      variant="contained"
      onClick={(event) => { handleContinue() }}
      disabled={isContinue || isDonateComplete || !sliderAmount}
      sx={{ mt: '2rem'}}
    >
      {'CONTINUE'}
    </Button>;


  //
  // Amount Selection (Slider or Input)
  //

  // Capture amount
  // Some way to capture amount value
  // Once captured and Payment component displayed, the intent is already registered at Stripe.
  // Have button which says: Donate Now/Accept the amout/etc
  // so we can go ahead and display the Payment fields
  // server converts to cents
  //const amountHtml = <p>Capture amount</p>;

  const amountHtml = (
    <Box sx={{ width: 400, marginLeft: 'auto', marginRight: 'auto', marginTop: '4rem' }}>
      <Typography variant="h4" sx={{mt: '1rem', mb: '2rem'}}>
	Donation Amount
      </Typography>
      <Slider
	color="secondary"
        value={sliderAmount}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
	valueLabelDisplay="on"
	step={settings.inputStep}
	min={settings.inputMin}
	max={settings.inputMax}
	sx={{ minWidth: 300}}
      />
      <br/>
      <AttachMoneyIcon sx={{fontSize: '2.6rem', verticalAlign: 'bottom'}}/>
      <Input
        value={sliderAmount}
        size="small"
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        inputProps={{
          step: settings.inputStep,
          min: settings.inputMin,
          max: settings.inputMax,
          type: 'number',
          'aria-labelledby': 'input-slider',
        }}
	sx={{ fontSize: '2rem', padding: '10px 0 0px 0'  }}
      />
    </Box>
  );

  //
  // Recurring Option
  //
  //const recurringHtml =<Box style={{height: '480px'}}>TODO</Box>;

  //
  // One time option
  //
  const onetimeHtml = isContinue && sliderAmount ? (
    <Box sx={{height: '480px'}}>
	<Payment stripePromise={stripePromise} amount={sliderAmount} />
    </Box>
  )
    :
    null;

  const onetimeCompleteHtml = isDonateComplete ? (
    <Box sx={{height: '480px', fontSize: '2rem'}}>
      <Completion stripePromise={stripePromise} /> 
    </Box>
  )
    :
    null;

  return (
      <Grid container spacing={2} data-where="DonateDtls" sx={{ width: '100%'}}>
	<Grid item xs={12}>
	    <DonateIntro />
	</Grid>
	<Grid item xs={12} sx={{ textAlign: 'center'}}>
	  { amountHtml  }
	</Grid>
	<Grid item xs={12} sx={{ textAlign: 'center'}}>
	  { continueHtml }
	</Grid>

	<Grid item xs={12} sx={{ textAlign: 'center'}}>
	  { onetimeHtml }
	</Grid>

	<Grid item xs={12} sx={{ textAlign: 'center'}}>
	  { onetimeCompleteHtml }
	</Grid>

      </Grid>
  );
};



	  /*
	      <Box sx={{ width: '28rem', borderBottom: '1px solid purple'}}>
	      <span >
	      One Time Donation
	      </span> 	
	      <Switch
	      color="secondary" 
	      checked={isRecurring}
	      onChange={handleTypeChange}
	      inputProps={{ 'aria-label': 'controlled' }}
	      />
	      <span >
	      Monthly Recurring Donation
	      </span>
	      </Box>

	      <Typography variant="h4" sx={{ mt: '1rem', mb: '1rem'}}>
	      { isRecurring ? 'Monthly Recurring Donation' : 'One Time Donation' }	
	      </Typography>

	      { amountHtml  }

	      { isRecurring ? recurringHtml : onetimeHtml }


	    */
	  
