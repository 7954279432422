import { genMuiThemes } from 'clnt-common';

const ltp    = '#f1ecf7';    // light primary - color with red tint - nav bar bg color
const lts    = '#9c27b0';    // light secondary - button bg in light mode (darker purple) - secondary / also used in the manifest.webmanifest
const ltpnl  = '#f5f3fb';   //#f1ecf7';    // light color with darker red tint for panel - 22 biggest... section bg color
const ltcpnl = '#f5f3fb';   //#f1ecff';    // light content panel -  (The same is used in manifest.webmanifest)

const dkp    = '#1c1a1a';    // '#121212',
const dks    = '#ce93d8';    // button bg in dark mode (lighter purple) - secondary
const dkpnl  = '#1c1a1a';    // compliment to ltpnl
const dkcpnl = '#1c1a10';    // dark content panel

const options = {
  fontHeader :      'Albra',
  fontText :        'Roboto',

  primaryLight:     ltp,
  secondaryLight:   dks, //lts,
  panelBgLight:     ltpnl,
  backpanelBgLight: ltp,
  contentpanelBgLight: ltcpnl,

  primaryDark:      dkp,
  secondaryDark:    lts, //dks,
  panelBgDark:      dkpnl,
  backpanelBgDark:  dkp,
  contentpanelBgDark:  dkcpnl,

  pagePadding : 1,
  pageGap : 0,

  // For use within pages.ts (rendered via PageContentFormat.jsx)
  // 5 sets of areas.
  areaLight: [{ panel: '', border: '#cccccc' },  // about
              { panel: '', border: '#3845f3' },  // service:matrix
              { panel: '#e8eafb', border: '' },  // service:chat
              { panel: '#af9d9d', border: '#cccffa' },  // service: *
              { panel: '', border: '' }],  
  areaDark:  [{ panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' }]  

};

export { themes };
let themes = genMuiThemes(options);
