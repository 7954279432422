import React from 'react'; // useState, useEffect,
import { Box, useTheme, Button, Grid, Typography } from '@mui/material'; // Avatar, Link as ExternalLink, , Paper
import { KImage } from 'clnt-common';

export { DonateIntro };
const DonateIntro = props => {
  //const theme = useTheme();
  //const { appState, dispatch } = useAppContext();

  // embeded style with blurred window
  // sx={{ textAlign: 'center', m:'auto', height: '380px', width: '900px', boxShadow: '0 0 60px 60px blue inset' }}
  // KImage: style={{margin: '60px'}} 
  //

  return (
    <Box sx={{ m: '2.0rem'}}>
      <Typography variant="h3" sx={{ textAlign: 'center', mb: '1rem'}}>
	Invest In Public Safety.
      </Typography>

      <Typography variant="h5" sx={{ mb: '1rem', textAlign:'center' }}>
	We are counting on your support to ensure public is informed and made aware about the unsafe recalled products.
      </Typography>
      <Typography variant="h5" sx={{ mb: '1rem', textAlign:'center'}}>
	Your contributions help sustain this site, promote awareness and save lives.
      </Typography>
      <Box sx={{ textAlign: 'center', m:'auto' }} >
	<KImage src='images/pages/elderlyCare.webp' style={{margin: '20px', width: '70%', borderRadius: '8px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} />
      </Box>
      <Typography variant="h5" sx={{ mb: '1rem', textAlign:'center'}}>
	It takes all of us.
      </Typography>

      <Typography variant="body1" sx={{ mb: '1rem', textAlign:'center', fontSize: 18}}>
	Web site user donations assist in maintaing and enhancing this website.
      </Typography>
      <Typography variant="body1" sx={{ mb: '1rem', textAlign:'center', fontSize: 18}}>
	Your gift will be made to Kripa, Inc and specifically for improving the site content and usability. 
      </Typography>

      {/*
      <Typography variant="body1" sx={{ mb: '1rem', textAlign:'center', fontSize: 18}}>
	You can do one time donation or a recurring monthly donations.
      </Typography>

      <Typography variant="h5" sx={{ mb: '1rem', textAlign:'center'}}>
	Already a sustainer?
      </Typography>

      <Typography variant="body1" sx={{ mb: '1rem', textAlign:'center', fontSize: 18}}>
	You can manage your monthly donation gift by logging into our partner processor site by clicking here (under development). 
      </Typography>
      */}

      <Typography variant="body1" sx={{ mt: '2rem', mb: '1rem', textAlign:'center', fontSize: 18}}>
	Have a comments or suggestions? Please use the Contact Us link below.
      </Typography>
    </Box>
  );
};
