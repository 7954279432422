//
// Following are the all available pages for this site.
// They are listed in the NAVBAR ORDER and FOOTER ORDER
// NOTE: We are lazy loading the base React component.
// 
const routes: Route[] = [
  // Navbar      
  { path: "/",                  import: () => import("./pages/Home") },
  { path: "/recall",            import: () => import("./pages/Recall") },
  { path: "/recall/:name",      import: () => import("./pages/recall/RecallTmpl") },
  { path: "/claim",             import: () => import("./pages/Claim") },
  { path: "/recent",            import: () => import("./pages/Recent") },
  { path: "/services",          import: () => import("./pages/Services") },
  { path: "/getstarted",        import: () => import("./pages/GetStarted") },
  { path: "/myrecalls",         import: () => import("./pages/MyRecalls") },
  { path: "/myaccount",         import: () => import("./pages/MyAccount") },
  { path: "/donate",            import: () => import("./pages/Donate") },

  // Footer 
  { path: "/about",             import: () => import("./pages/About.mdx") },
  { path: "/disclaimers",       import: () => import("./pages/Disclaimers.mdx") },
  { path: "/credits",           import: () => import("./pages/Credits.mdx") },
  { path: "/faq",               import: () => import("./pages/Faq") },
  { path: "/learn",             import: () => import("./pages/Learn") },
  { path: "/howto",             import: () => import("./pages/HowTo.mdx") },
  { path: "/HowToList",         import: () => import("./pages/HowToList") },
  { path: "/HowToPwa",          import: () => import("./pages/HowToPwa") },
  { path: "/HowToRegProd",      import: () => import("./pages/HowToRegProd") },
  { path: "/HowToRegVehi",      import: () => import("./pages/HowToRegVehi") },
  { path: "/HowToSearch",       import: () => import("./pages/HowToSearch") },
  { path: "/HowToSelCat",       import: () => import("./pages/HowToSelCat") },
  { path: "/HowToSetDel",       import: () => import("./pages/HowToSetDel") },
  { path: "/privacy",           import: () => import("./pages/privacy.mdx") },
  { path: "/termsandconditions",import: () => import("./pages/termsandconditions.mdx") },

  // Draft
  //{ path: "/select",            import: () => import("./pages/Select") },
  { path: "/pricing",           import: () => import("./pages/Pricing") },
  { path: "/partners",          import: () => import("./pages/Partners") },
  { path: "/portfolio",         import: () => import("./pages/Portfolio") },

  { path: "/blog",              import: () => import("./pages/blog/BlogHome") },
  { path: "/blog/:name",        import: () => import("./pages/blog/BlogTmpl") },

  { path: "*",                  import: () => import("./pages/FourOFour") },
];

export default routes;
